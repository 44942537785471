import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Container, Row, Col } from "reactstrap";
import { apiUrl, apiToken } from "../constants";

function QRView() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const type = urlParams.get("t");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const getDelegateData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getDelegateData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: apiToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const delegate = {
          name: `${row.firstName} ${row.lastName}`,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          shortId: row.shortId,
        };
        setData(delegate);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const getSpeakerData = () => {
    setLoading(true);
    fetch(`${apiUrl}/getSpeakerData/${id}`, {
      method: "get",
      headers: new Headers({
        Authorization: apiToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const [row] = (data && data.rows) || [{}];
        const speaker = {
          name: row.name,
          title: row.title,
          company: row.company,
          country: row.country,
          email: row.email,
          phone: row.phone,
          id: row.id,
          shortId: row.shortId,
        };
        setData(speaker);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (type === "s") {
      getSpeakerData();
    } else {
      getDelegateData();
    }
  }, [type]);

  return (
    <>
      <Navbar />
      {data.id ? (
        <>
          <ProfilePageHeader title="Delegate Information" bgColor="#fff" />
          <div className="section profile-content" style={{ background: "#fff" }}>
            <Container className="my-5">
              <Row className="justify-content-center">
                <Col lg={8} xs={12} className="text-center text-white ">
                  <h2 className="text-center text-dark text-700 my-0 align-self-center mr-3 text-uppercase">
                    {data.name}
                  </h2>
                  <br />
                  <h4 className="text-center text-dark text-400 my-0 align-self-center mr-3 ">
                    {data.title}
                  </h4>
                  <h4 className="text-center text-dark text-700 my-0 align-self-center mr-3 ">
                    {data.company}, {data.country}
                  </h4>
                  <br />
                  <h4 className="text-center text-dark text-700 mt-2 align-self-center mr-3">
                    <a
                      style={{ color: "#5ab02f", wordBreak: "break-word" }}
                      href={`mailto:${data.email}`}
                    >
                      {data.email}
                    </a>
                  </h4>
                  {data.phone && (
                    <h4>
                      <a style={{ color: "#5ab02f" }} href={`tel:${data.phone}`}>
                        {data.phone}
                      </a>
                    </h4>
                  )}
                </Col>
                <Col lg={6} xs={12} className="text-center text-white m-4">
                  <QRCode value={`https://api.rapid-events.com/${data.shortId}`} />
                </Col>
              </Row>
              <Row className="justify-content-center"></Row>
            </Container>
          </div>
        </>
      ) : (
        <ProfilePageHeader title={loading ? "LOADING ...." : `No Data`} bgColor="#fff" />
      )}

      <div className="section profile-content" style={{ padding: 0, background: "#fff" }}></div>
      <Footer />
    </>
  );
}

export default QRView;
