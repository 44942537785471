import { DataContext } from "DataContainer";
import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";

function Sponsor() {
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const { sponsors: allSponsors } = useContext(DataContext);

  const rows = groupBy("panel")(allSponsors);
  if (allSponsors.length === 0) {
    return null;
  }

  const containerStyle = {
    width: "100%", // Adjust as needed for your layout
    aspectRatio: "3 / 2", // Enforces 3:2 aspect ratio
    backgroundColor: "white", // Sets background to white
    display: "flex", // Ensures proper centering of the image
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Prevents image overflow
  };

  const imageStyle = {
    width: "100%", // Scales image to fit the width
    height: "100%", // Scales image to fit the height
    objectFit: "contain", // Ensures the entire image is visible
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-g"
            style={{
              fontSize: "3rem",
            }}
          >
            2025 PARTNERS & SPONSORS
          </h1>
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"} key={s.id}>
                  <a href={s.url}>
                    <h4 className="text-center text-400 text-white pb-2">{s.type}</h4>
                    <Card className="rounded-0">
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            <div style={containerStyle}>
                              <img
                                src={s.image}
                                alt={s.name}
                                width="100%"
                                className="rounded-0"
                                style={imageStyle}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "GIS PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esri.com/en-us/digital-twin/overview",
    lg: 6,
  },
];
